import React, { useEffect, useState, useRef, useContext } from "react";
import { Form, Row, InputGroup, Button, Col } from "react-bootstrap";
import Loader from "react-loader-spinner";
import Head from "next/head";
import { appServerSideProps } from "../../../utils/getAppService";
import Link from "next/link";
import DraftKings from "../../../assets/images/value-finder/DraftKings.png";
import FiveDimes from "../../../assets/images/5DimesLogo.png";
import FanDuel from "../../../assets/images/value-finder/Fan_Duel.png";
import Caesars from "../../../assets/images/value-finder/Caesars.png";
import BetMGM from "../../../assets/images/value-finder/BetMGM.png";
import SIALogo from "../../../assets/images/SIALogo.png";
import PointsBetMini from "../../../assets/images/value-finder/PointsBetMini.png";
import Fanatics from "../../../assets/images/value-finder/fanatics.png";
import ESPN_BET from "../../../assets/images/value-finder/ESPN_BET.png";
import BetOnlineLogo from "../../../assets/images/BetOnlineLogo.png";
import BookMakerLogo from "../../../assets/images/BookMakerLogo.png";
import Utils from "../../../utils/Utils";
import { authGetRequest, postRequest, getRequest } from "../../../utils/authGetRequest";
import MainLayout from "../../../components/MainLayout/MainLayout";
import SQBestBetsSreenshot from "../../../assets/images/SQBestBetsSreenshot.png";
import { useCookies } from "react-cookie";
import dayjs from "dayjs";

import TableSkeleton from "../../../components/table/TableSkeleton";
import ScrollableTable from "../../../components/table/ScrollableTable";
import { TableHeader } from "../../../components/table/TableHeader";
import useInfiniteScroll from "../../../components/hooks/useInfiniteScroll";
import useWindowResize from "../../../components/hooks/useWindowResize";
import { AppContext } from "../../../context/state";
import transformTime from "../linevalue-welcome/utils/transformTime";
import { useRouter } from "next/router";
import advancedFormat from "dayjs/plugin/advancedFormat";
import ContentfulBlogs from "../../../components/contentfulBlogs/ContentfulBlogs";
import LineValueStats from "../../../components/linevalue/linevalue-stats";
import Lock from "../../../components/Lock";
import WinningMetrics from "../../../components/WinningMetrics";

dayjs.extend(advancedFormat);
function useQuery() {
  return new URLSearchParams();
}

const SQScoreHeader = [
  {
    title: Lock,
    showImage: false,
    sortable: false,
    key: "88-88",
    classList: ["dummyLockToolTip"],
    tooltip: {
      title: "Only available to ShotQuality Subscribers",
      text: "To see this Information, purchase any ShotQuality Bets subscription",
      link: "/pricing",
    },
  },
];
const pendingPlaysHeader = [
  {
    title: "Date",
    showImage: false,
    sortable: false,
    key: "Date",
    classList: ["shrink-column"],
  },
  {
    title: "Time (ET)",
    showImage: false,
    sortable: false,
    key: "Time",
    classList: ["shrink-column"],
  },
  {
    title: "Home Team",
    showImage: false,
    sortable: false,
    key: "Home",
    classList: ["shrink-column"],
  },
  {
    title: "Away Team",
    showImage: false,
    sortable: false,
    key: "Away",
    classList: ["shrink-column"],
  },
  {
    title: "Home Projected Score",
    showImage: false,
    sortable: false,
    key: "Projected Home Score",
    classList: ["shrink-column"],
  },
  {
    title: "Away Projected Score",
    showImage: false,
    sortable: false,
    key: "Projected Away Score",
    classList: ["shrink-column"],
  },
  {
    title: "Total Projected Score",
    showImage: false,
    sortable: false,
    key: "Total Projected Score",
    classList: ["shrink-column"],
  },
  {
    title: "Side With Value",
    showImage: false,
    sortable: false,
    key: "Side with Value",
    classList: ["shrink-column"],
  },
  {
    title: "Line at Time of Play",
    showImage: false,
    sortable: false,
    key: "Line at Time of Play",
    classList: ["shrink-column"],
  },
  {
    title: "Play Line Value",
    showImage: false,
    sortable: false,
    key: "Play Line Value",
    classList: ["shrink-column"],
  },
  {
    title: "Play",
    showImage: false,
    sortable: false,
    key: "play",
    classList: ["shrink-column"],
  },
];
const miscTableHeader = [
  {
    title: "Date & Time",
    showImage: false,
    sortable: true,
    key: "dateTime",
    classList: ["mm-stretch-column"],
  },
  {
    title: "Matchup",
    showImage: false,
    sortable: false,
    key: "Matchup",
    classList: ["mm-stretch-column"],
  },

  {
    title: "ShotQuality Projection",
    showImage: false,
    sortable: false,
    key: "SQProjection",
    classList: ["mm-stretch-column"],
  },

  {
    title: "Plays",
    showImage: false,
    sortable: true,
    key: "Plays",
    classList: ["mm-stretch-column"],
  },
];
export const books = [
  { title: "DraftKings", src: DraftKings.src },
  { title: "FanDuel", src: FanDuel.src },
  { title: "Caesars", src: Caesars.src },
  { title: "BetMGM", src: BetMGM.src },
  { title: "PointsBet", src: PointsBetMini.src },
  { title: "BetOnline", src: BetOnlineLogo.src },
  { title: "Bookmaker", src: BookMakerLogo.src },
  { title: "5Dimes", src: FiveDimes.src },
  { title: "SIA", src: SIALogo.src },
  { title: "Bovada", src: "/static/media/logos/Bovada.png" },
  { title: "Fanatics", src: Fanatics.src },
  { title: "ESPN BET", src: ESPN_BET.src },
];
export const getBookLogo = (selectedBook) => {
  let book = books.find((book) => book.title === selectedBook);
  return book.src;
};

const BestBets = ({ user }) => {
  const router = useRouter();
  let leagueFromUrl = router.query?.league;
  const tableRef = useRef();
  const [asc, setAsc] = useState(true);
  const [teamData, setTeamData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [filteredTeamData, setFilteredTeamData] = useState([]);
  const windowSize = useWindowResize();
  const { store, setStore } = useContext(AppContext);
  const [sortedKey, setSortedKey] = useState("");
  let query = useQuery();
  let ascOrder = true;
  const [columnType, setColumnType] = useState(() => {
    return query.get("columnType") ?? "offen";
  });

  const [slicedArray] = useInfiniteScroll({
    array: filteredTeamData,
    scrollType: windowSize?.["width"] > 768 ? "window" : "parent",
    parentRef: tableRef,
    itemsPerView: 35,
  });
  const [isValid, setIsValid] = useState(false);
  const [cookies, setCookie] = useCookies(["marchmadness_subscribed"]);
  const [marchmadnessSubscribed, setMarchmadnessSubscribed] = useState(false);
  // const { loading, error, data } = useQuery(getAllWpPostQuery);
  const [domLoaded, setDomLoaded] = useState(false);
  const [tableLoaded, setTableLoaded] = useState(false);

  const [authToken, setAuthToken] = useState(null);
  const [emailAddress, setEmailAddress] = useState("");
  const pendingPlaysTableRef = useRef();
  const [pendingPlays, setPendingPlays] = useState([]);
  const [pendingPlaySlicedArray, setPendingPlaySlicedArray] = useState([]);
  const [pendingPlaySlicedArrayIndex, setPendingPlaySlicedArrayIndex] = useState(0);
  const [filteredPendingPlays, setFilteredPendingPlays] = useState([]);
  const [year, setYear] = useState("2024");

  const isStandardPlan = Utils.hasStandardPlan(user) || Utils.hasTrial(user);
  const isPremiumPlan = Utils.hasPremiumPlan(user);
  const isPaidPlan = (isStandardPlan || isPremiumPlan) && user?.has_paid;

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  if (leagueFromUrl === "ncaa") {
    leagueFromUrl = "college_mens";
  }

  useEffect(() => {
    setStore({ ...store, leagueType: leagueFromUrl ? leagueFromUrl : store.leagueType });
  }, [leagueFromUrl]);
  useEffect(() => {
    setIsValid(validateEmail(emailAddress));
  }, [emailAddress]);

  const handleSubmit = () => {
    if (isValid) {
      postRequest("/api/marchmadness/email", {
        email: emailAddress,
      });

      setCookie("marchmadness_subscribed", true, {
        maxAge: 31536000,
        path: "/",
      });
      setMarchmadnessSubscribed(true);
    }
  };
  useEffect(() => {
    setDomLoaded(true);
    if (cookies.token_access) {
      setAuthToken(cookies.token_access);
    }
  }, []);

  useEffect(() => {
    setMarchmadnessSubscribed(cookies.marchmadness_subscribed);
  }, [marchmadnessSubscribed, authToken]);

  useEffect(() => {
    if (domLoaded && user && user?.has_paid) {
      authGetRequest(`/api/${leagueFromUrl}/value-finder/games`, authToken)
        .then((response) => response.data)
        .then((response) => {
          setTeamData(
            response.data
              .filter((team) => team.Play)
              .map((item) => {
                return { ...item, dateTime: `${item?.Date} ${item?.Time}` };
              })
          );
          setTableLoaded(true);
        })
        .catch(async (err) => {
          if (err.message.includes("401")) {
            try {
              const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL_API_URL}/api/refresh`, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${authToken}`,
                },
                body: authToken,
              })
                .then((res) => res.json())
                .then((response) => {
                  if (response.access_token) {
                    setCookie("token_access", response.access_token);
                    setAuthToken(response.access_token);
                  }
                });
            } catch (error) {
              console.error(error);
            }
          }
          console.error(err);
        });

      authGetRequest(`/api/${leagueFromUrl}/value-finder/pending-plays`, authToken)
        .then((response) => response.data)
        .then((response) => {
          const bestBetsData = response.data.filter((it) => it?.Best_Bet === 1);
          setPendingPlays(bestBetsData);
        })
        .catch(async (err) => {
          if (err.message.includes("401")) {
            try {
              const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL_API_URL}/api/refresh`, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${authToken}`,
                },
                body: authToken,
              })
                .then((res) => res.json())
                .then((response) => {
                  if (response.access_token) {
                    setCookie("token_access", response.access_token);
                    setAuthToken(response.access_token);
                    router.reload();
                  }
                });
            } catch (error) {
              console.error(error);
            }
          }
          console.error(err);
        });
    }

    if ((domLoaded && !user) || (!user?.has_paid && !Utils.hasTrial(user))) {
      if (leagueFromUrl) {
        fetch(`${process.env.NEXT_PUBLIC_API_URL_API_URL}/api/${leagueFromUrl}/value-finder/games/unprotected`)
          .then((response) => response.json())
          .then((response) => {
            setTeamData(
              response.data.map((item) => {
                return { ...item, dateTime: `${item?.Date} ${item?.Time}` };
              })
            );
            setTableLoaded(true);
          })
          .catch(async (err) => {
            console.error(err);
          });

        fetch(`${process.env.NEXT_PUBLIC_API_URL_API_URL}/api/${leagueFromUrl}/value-finder/pending-plays/unprotected`)
          .then((response) => response.json())
          .then((response) => {
            const bestBetsData = response.data.filter((it) => it?.Best_Bet === 1);
            setPendingPlays(bestBetsData);
          })
          .catch(async (err) => {
            console.error(err);
          });
      }
    }
  }, [authToken, leagueFromUrl, user]);

  // teamData.map((team) => {
  //   const { BetMGM, "5Dimes": FiveDimes, BetOnline, Bookmaker, Bovada, Caesars, DraftKings, FanDuel } = team;
  //   return {
  //     BetMGM: BetMGM === null ? 0 : BetMGM,
  //     FiveDimes: FiveDimes === null ? 0 : FiveDimes,
  //     BetOnline: BetOnline === null ? 0 : BetOnline,
  //     Bookmaker: Bookmaker === null ? 0 : Bookmaker,
  //     Bovada: Bovada === null ? 0 : Bovada,
  //     Caesars: Caesars === null ? 0 : Caesars,
  //     DraftKings: DraftKings === null ? 0 : DraftKings,
  //     FanDuel: FanDuel === null ? 0 : FanDuel,
  //   };
  // })

  function sortByBestKey(array, key) {
    return array.sort((a, b) => {
      const aValue = a[`${a[key]}`];
      const bValue = b[`${b[key]}`];
      if (asc) {
        if (aValue >= 0) {
          return aValue + bValue;
        } else {
          return aValue - bValue;
        }
      } else {
        if (bValue >= 0) {
          return bValue + aValue;
        } else {
          return bValue - aValue;
        }
      }
    });
  }

  const newSort = (columnName) => {
    setAsc(!asc);
    if (columnName === "Plays") {
      let newData = sortByBestKey(filteredTeamData, "Best");
      setFilteredTeamData([...newData]);
      return [...newData];
    } else {
      sort(columnName);
    }
  };

  useEffect(() => {
    if (teamData.length > 0) {
      if (leagueFromUrl === "college_mens" || leagueFromUrl === "ncaa") {
        const ncaaBetsData = teamData.filter((team) => team.Best_Bet === 1);
        setFilteredTeamData(isPaidPlan ? ncaaBetsData : [ncaaBetsData[0]]);
      } else {
        setFilteredTeamData(isPaidPlan ? teamData : [teamData[0]]);
      }
    }
    setTimeout(() => {
      setIsDataLoaded(true);
    }, 500);
  }, [teamData, isPaidPlan, leagueFromUrl]);

  const addUpTeamScore = (teamAScore, teamBScore) => {
    if (teamAScore > teamBScore) {
      return teamAScore + teamBScore;
    } else {
      return teamBScore + teamAScore;
    }
  };

  const calculateScore = (teamAScore, teamBScore) => {
    if (teamAScore > teamBScore) {
      return teamAScore - teamBScore;
    } else {
      return teamBScore - teamAScore;
    }
  };

  const sort = (key) => {
    let res = [];

    let keySumm = {};
    let rawData = filteredTeamData.length > 0 ? filteredTeamData : teamData;

    let order = asc !== true ? "asc" : "desc";

    res = rawData.sort(Utils.dynamicsort(key, order, keySumm));

    setFilteredTeamData([...res]);
    return [...res];
  };

  useEffect(() => {
    if (pendingPlays.length > 0) {
      setFilteredPendingPlays(pendingPlays);
    }
  }, [pendingPlays]);
  useEffect(() => {
    setPendingPlaySlicedArray(filteredPendingPlays.slice(0, pendingPlaySlicedArrayIndex + 35));
  }, [filteredPendingPlays, pendingPlaySlicedArrayIndex]);
  const showPendingPlayLoadMoreButton = pendingPlaySlicedArray.length < filteredPendingPlays.length;
  const handlePendingPlayLoadMore = () => {
    setPendingPlaySlicedArrayIndex(pendingPlaySlicedArrayIndex + 35);
  };
  return (
    <>
      <Head>
        <title>
          {leagueFromUrl == "wnba" ? "WNBA " : leagueFromUrl === "nba" ? "NBA " : "NCAA "} Best Bets Today's Basketball
          Best Bets Powered by ShotQuality
        </title>
        <meta
          name="description"
          content={`The home of our ${
            leagueFromUrl == "wnba" ? "WNBA " : leagueFromUrl === "nba" ? "NBA " : "NCAA "
          } best bets. Today's ${
            leagueFromUrl == "wnba" ? "WNBA " : leagueFromUrl === "nba" ? "NBA " : "NCAA "
          } best spread and totals bets powered by ShotQuality.`}
        />
      </Head>
      <MainLayout>
        <div style={{ padding: "0 3.5%" }}>
          <main id="ContentBox" style={{ minHeight: "100vh" }}>
            <div className="MiscStats ValueBracket">
              <div className="row">
                <div className="text-left ValueBracketTitle col-md-4 col-sm-12">
                  <h2>
                    {leagueFromUrl === "college_mens" ? "NCAA" : leagueFromUrl === "wnba" ? "WNBA" : "NBA"} Best Bets
                  </h2>
                  <p>
                    Best bets takes the best of our core basketball prediction model with inputs from our simulation
                    models. See {leagueFromUrl === "college_mens" ? "NCAA" : leagueFromUrl === "wnba" ? "WNBA" : "NBA"}{" "}
                    best bets across today’s{" "}
                    {leagueFromUrl === "college_mens" ? "NCAA" : leagueFromUrl === "wnba" ? "WNBA" : "NBA"} slate.{" "}
                    {leagueFromUrl === "college_mens" ? "NCAA" : leagueFromUrl === "wnba" ? "WNBA" : "NBA"} Predictions
                    and match previews are available for every game of the season. The best{" "}
                    {leagueFromUrl === "college_mens" ? "NCAA" : leagueFromUrl === "wnba" ? "WNBA" : "NBA"} picks and
                    predictions are here on Best Bets.{" "}
                  </p>
                </div>
                {leagueFromUrl !== "college_mens" && (
                  <div className="ValueBracketTitle col-md-8 col-sm-12">
                    <LineValueStats league={leagueFromUrl} page="best-bets" />
                  </div>
                )}
              </div>
              {domLoaded && !marchmadnessSubscribed && !authToken ? (
                <div className="col-md-12 dummyLockWrapper">
                  <div className="imgWrapper">
                    <img src={SQBestBetsSreenshot.src} className="bracketBackground" alt="" />
                  </div>

                  <Col
                    className="dummyLock"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      position: "absolute",
                      top: "20px",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    <h3>
                      Unlock {leagueFromUrl === "college_mens" ? "NCAA" : leagueFromUrl === "wnba" ? "WNBA" : "NBA"}{" "}
                      Best Bets
                    </h3>
                    <p>
                      {/* Unlock ShotQuality Best Bets. To see all data on this page, please enter your email to get access. */}
                      Activate your membership to get instant access to Best Bets, Core Model Predictions, select live{" "}
                      {leagueFromUrl === "college_mens" ? "NCAA" : leagueFromUrl === "wnba" ? "WNBA" : "NBA"} games, and
                      more.
                    </p>
                    <div className="requestEmailWrapper">
                      <Row className="mm-email-wrapper">
                        <Form className="mm-form">
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <InputGroup className="mb-3 mm-input-main-wrapper">
                              <Button
                                className={`mm-access-button mx-auto`}
                                style={{
                                  backgroundColor: "#f26b0b",
                                  color: "white",
                                  padding: "10px 30px",
                                  border: "none",
                                }}
                                onClick={() => router.push("/welcome")}
                              >
                                Activate Your Membership 👍🏾
                              </Button>
                            </InputGroup>
                          </Form.Group>
                        </Form>
                      </Row>
                    </div>
                  </Col>
                </div>
              ) : (
                <section className="page-content">
                  <div className="container-fluid">
                    {domLoaded && (
                      <>
                        <ScrollableTable top="0px" ref={tableRef}>
                          <div className="table-responsive table-space" style={{ overflow: "auto" }}>
                            {/* {isDataLoaded &&
                              filteredTeamData.length === 0 &&
                              (leagueFromUrl === "nba" || store.leagueType === "nba") && (
                                <div style={{ display: "flex", "justify-content": "center" }}>
                                  <h4>
                                    {" "}
                                    🧠 The ShotQuality{" "}
                                    {store.leagueType.toLowerCase() === "college_mens"
                                      ? "NCAA"
                                      : store.leagueType.toUpperCase()}{" "}
                                    Model is Learning.{" "}
                                    {store.leagueType.toLowerCase() === "college_mens"
                                      ? "NCAA"
                                      : store.leagueType.toUpperCase()}{" "}
                                    Best Bets will be available in 2-4 weeks
                                  </h4>
                                </div>
                              )} */}
                            {isDataLoaded && filteredTeamData.length === 0 && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: "50px",
                                }}
                              >
                                <h3 style={{ margin: "0 auto" }}>
                                  There are no{" "}
                                  {leagueFromUrl.toLowerCase() === "nba"
                                    ? "NBA"
                                    : leagueFromUrl.toLowerCase() === "wnba"
                                    ? "WNBA"
                                    : "NCAA"}{" "}
                                  Best Bets available.{" "}
                                </h3>
                              </div>
                            )}
                            {filteredTeamData.length > 0 && (
                              <table className="table tl-fixed-desktop" style={{ position: "relative" }}>
                                <thead className="sticky-th-2">
                                  <tr className="text-center header-container-table mobile-view-hide">
                                    <TableHeader
                                      headers={miscTableHeader}
                                      onSortClick={newSort}
                                      ascOrder={asc ? "asc" : "desc"}
                                      sortedKey={sortedKey}
                                    />
                                  </tr>
                                </thead>

                                <tbody>
                                  {slicedArray.map((team, index) => {
                                    const bestLine = team.Best;
                                    if (team.is_spread || !team.is_spread) {
                                      return (
                                        <tr
                                          className="mobile-view-show best-bets"
                                          key={`${team["Date"]}_${team["Time"]}_${team["Home"]}_${team["Away"]}_${index}`}
                                        >
                                          <td
                                            className="date-time"
                                            style={{
                                              textAlign: "center",
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            <Link
                                              href={`/${
                                                leagueFromUrl === "college_mens" ? "ncaa" : leagueFromUrl
                                              }/matchup-output/${team["Home"]}/${team["Away"]}/${team["Date"]}T${
                                                team["Time"]
                                              }`}
                                              target={"_blank"}
                                            >
                                              {`${dayjs(team.Date).format("MMM. Do")} ${
                                                team["Time"].length > 8
                                                  ? transformTime(team["Time"], true)
                                                  : team["Time"]
                                              } ET`}
                                            </Link>
                                          </td>
                                          <td className="home-away-team">
                                            <Link
                                              href={`/${
                                                leagueFromUrl === "college_mens" ? "ncaa" : leagueFromUrl
                                              }/matchup-output/${team["Home"]}/${team["Away"]}/${team["Date"]}T${
                                                team["Time"]
                                              }`}
                                              target={"_blank"}
                                            >
                                              <img
                                                src={`http://shotqualitybets.com/static/media/logos/${team["Home"]}.png`}
                                                alt={""}
                                                className="img-fluid w-25 pull-left GameCard__team--logo logo-left2"
                                              />
                                              &nbsp; {team["Home"]} {"  "}
                                              <b>vs</b> {"  "}
                                              <img
                                                src={`http://shotqualitybets.com/static/media/logos/${team["Away"]}.png`}
                                                alt={""}
                                                className="img-fluid w-25  GameCard__team--logo"
                                              />
                                              &nbsp; {team["Away"]}
                                            </Link>
                                          </td>
                                          <td className="projected-winner">
                                            <span className="mobile-header">
                                              <b>ShotQuality Projection</b>
                                            </span>
                                            <span>
                                              {" "}
                                              <img
                                                src={`http://shotqualitybets.com/static/media/logos/${team["Projected Winner"]}.png`}
                                                alt={""}
                                                className="img-fluid w-25  GameCard__team--logo"
                                              />{" "}
                                              <b>{` ${team["Projected Winner"]}`}</b> wins{" "}
                                              <b>{`${
                                                team["Projected Winner"] === team["Home"]
                                                  ? team["Projected Home Score"]
                                                  : team["Projected Away Score"]
                                              }`}</b>{" "}
                                              <svg
                                                width="7"
                                                height="8"
                                                viewBox="0 0 7 8"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M0.500001 4.86603C-0.166666 4.48113 -0.166667 3.51887 0.499999 3.13397L5 0.535898C5.66667 0.150998 6.5 0.632123 6.5 1.40192L6.5 6.59808C6.5 7.36788 5.66667 7.849 5 7.4641L0.500001 4.86603Z"
                                                  fill="#F26B0B"
                                                />
                                              </svg>{" "}
                                              <b>
                                                {`${
                                                  team["Projected Winner"] === team["Home"]
                                                    ? team["Projected Away Score"]
                                                    : team["Projected Home Score"]
                                                }`}
                                              </b>
                                            </span>
                                            <br />
                                            <span>
                                              Total Score:{" "}
                                              <b>{` ${addUpTeamScore(
                                                team["Projected Home Score"],
                                                team["Projected Away Score"]
                                              ).toFixed(1)}`}</b>
                                            </span>
                                            <br />
                                            <span>
                                              Difference: <b>{`${team["Projected Winner"]}`}</b> wins by{" "}
                                              <b>{`${calculateScore(
                                                team["Projected Home Score"],
                                                team["Projected Away Score"]
                                              ).toFixed(2)}`}</b>
                                            </span>
                                            <br />
                                            <span>
                                              Points of Value: <b>{`${team["Points of Value"]}`}</b>
                                            </span>
                                            <br />
                                          </td>
                                          <td
                                            className="side-with-value"
                                            style={
                                              !isPaidPlan
                                                ? {
                                                    textAlign: "center",
                                                    color: "transparent",
                                                    textShadow: "0 0 7px #000",
                                                    position: "relative",
                                                  }
                                                : {
                                                    textAlign: "center",
                                                    verticalAlign: "middle",
                                                  }
                                            }
                                          >
                                            <span className="mobile-header" style={{ marginBottom: "10px !important" }}>
                                              <b>Plays</b>
                                            </span>
                                            <p className="mm-best-bets-tag" style={{ display: "inline" }}>
                                              {`${
                                                team["Side with Value"]
                                                  ? Utils.capitalizeFirstLetter(team["Side with Value"])
                                                  : ""
                                              }`}{" "}
                                              {team["Side with Value"] === team["Away"]
                                                ? team.is_spread
                                                  ? team[`${team["Best"]}`] * -1 > 0
                                                    ? "+" + team[`${team["Best"]}`] * -1
                                                    : team[`${team["Best"]}`] * -1
                                                  : team[`${team["Best"]}`]
                                                : team[team.Best] > 0
                                                ? team.is_spread
                                                  ? "+" + team[`${team["Best"]}`]
                                                  : team[`${team["Best"]}`]
                                                : team[`${team["Best"]}`]}
                                            </p>{" "}
                                            <p style={{ display: "inline" }}>at </p>
                                            {team.Best === "BetMGM" ? (
                                              <p style={{ display: "inline" }}>
                                                <Link
                                                  style={{ display: "inline" }}
                                                  href={`https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1686386`}
                                                >
                                                  <img
                                                    style={{ marginLeft: "0px" }}
                                                    className="mm-booking-logo"
                                                    src={getBookLogo(team["Best"])}
                                                  />
                                                </Link>
                                              </p>
                                            ) : (
                                              <p style={{ display: "inline" }}>
                                                <img
                                                  style={{ marginLeft: "0px" }}
                                                  className="mm-booking-logo"
                                                  src={getBookLogo(team["Best"])}
                                                />
                                              </p>
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  })}

                                  {!slicedArray.length & user ? (
                                    <div className="loader mt-5">
                                      <Loader type={"Oval"} visible color={"orange"} height={60} width={60} />
                                    </div>
                                  ) : null}
                                </tbody>
                              </table>
                            )}
                          </div>
                        </ScrollableTable>

                        {leagueFromUrl !== "nba" && pendingPlays.length > 0 && (
                          <>
                            <div className="row align-items-center">
                              <div className="col-12 mt-3 text-center switch-container">
                                <h3 className="d-inline-block mr-2 mb-0">Pending Plays</h3>
                                <p className="mb-0">
                                  These plays were locked as of:{" "}
                                  {(leagueFromUrl === "college_mens" && "10AM EST for NCAA") ||
                                    (leagueFromUrl === "nba" && "1am EST today for NBA") ||
                                    (leagueFromUrl === "wnba" && "9am EST today for WNBA.")}
                                  . These plays will be reflected in the core model record.
                                </p>
                              </div>
                            </div>

                            <ScrollableTable top="0px" ref={pendingPlaysTableRef}>
                              <div className="table-responsive table-space">
                                <table className="table tl-fixed-desktop" style={{ position: "relative" }}>
                                  <thead className="sticky-th-2">
                                    <tr className="text-center header-container-table">
                                      <TableHeader
                                        headers={pendingPlaysHeader}
                                        onSortClick={() => {}}
                                        ascOrder={ascOrder}
                                        sortedKey={sortedKey}
                                      />
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {pendingPlays.length > 0 ? (
                                      <>
                                        {pendingPlaySlicedArray.map((team, index) => {
                                          return (
                                            <tr
                                              key={`${team["Date"]}_${team["Time"]}_${team["Home"]}_${team["Away"]}_${index}`}
                                            >
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                  verticalAlign: "middle",
                                                }}
                                              >
                                                {dayjs(team.Date).format("MMM. Do")}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                  verticalAlign: "middle",
                                                }}
                                              >
                                                {team["Time"].length > 10
                                                  ? transformTime(team["Time"], true)
                                                  : transformTime(team["Time"], false)}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                  verticalAlign: "middle",
                                                }}
                                              >
                                                <Link
                                                  href={`/team/${leagueFromUrl}/${year}/${team["Home"]}`}
                                                  style={{ color: "#212529" }}
                                                  target={"_blank"}
                                                >
                                                  {team.Home}
                                                </Link>
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                  verticalAlign: "middle",
                                                }}
                                              >
                                                <Link
                                                  href={`/team/${
                                                    leagueFromUrl === "college_mens" ? "ncaa" : leagueFromUrl
                                                  }/${year}/${team["Away"]}`}
                                                  style={{ color: "#212529" }}
                                                  target={"_blank"}
                                                >
                                                  {team.Away}
                                                </Link>
                                              </td>
                                              <td
                                                style={
                                                  !isPaidPlan
                                                    ? {
                                                        textAlign: "center",
                                                        color: "transparent",
                                                        textShadow: "0 0 7px #000",
                                                        position: "relative",
                                                      }
                                                    : {
                                                        textAlign: "center",
                                                        verticalAlign: "middle",
                                                      }
                                                }
                                              >
                                                {!isPaidPlan ? (
                                                  <>
                                                    <TableHeader headers={SQScoreHeader} />
                                                    78.9
                                                  </>
                                                ) : (
                                                  team["Projected Home Score"]
                                                )}
                                              </td>
                                              <td
                                                style={
                                                  !isPaidPlan
                                                    ? {
                                                        textAlign: "center",
                                                        color: "transparent",
                                                        textShadow: "0 0 7px #000",
                                                        position: "relative",
                                                      }
                                                    : {
                                                        textAlign: "center",
                                                        verticalAlign: "middle",
                                                      }
                                                }
                                              >
                                                {!isPaidPlan ? (
                                                  <>
                                                    <TableHeader headers={SQScoreHeader} />
                                                    78.9
                                                  </>
                                                ) : (
                                                  team["Projected Away Score"]
                                                )}
                                              </td>
                                              <td
                                                style={
                                                  !isPaidPlan
                                                    ? {
                                                        textAlign: "center",
                                                        color: "transparent",
                                                        textShadow: "0 0 7px #000",
                                                        position: "relative",
                                                      }
                                                    : {
                                                        textAlign: "center",
                                                        verticalAlign: "middle",
                                                      }
                                                }
                                              >
                                                {!isPaidPlan ? (
                                                  <>
                                                    <TableHeader headers={SQScoreHeader} />
                                                    78.9
                                                  </>
                                                ) : (
                                                  Math.round(
                                                    (team["Projected Home Score"] + team["Projected Away Score"]) * 10
                                                  ) / 10
                                                )}
                                              </td>
                                              <td
                                                style={
                                                  !isPaidPlan
                                                    ? {
                                                        textAlign: "center",
                                                        color: "transparent",
                                                        textShadow: "0 0 7px #000",
                                                        position: "relative",
                                                      }
                                                    : {
                                                        textAlign: "center",
                                                        verticalAlign: "middle",
                                                      }
                                                }
                                              >
                                                {!isPaidPlan ? (
                                                  <>
                                                    <TableHeader headers={SQScoreHeader} />
                                                    78.9
                                                  </>
                                                ) : (
                                                  team["Side with Value"]
                                                )}
                                              </td>
                                              <td
                                                style={
                                                  !isPaidPlan
                                                    ? {
                                                        textAlign: "center",
                                                        color: "transparent",
                                                        textShadow: "0 0 7px #000",
                                                        position: "relative",
                                                      }
                                                    : {
                                                        textAlign: "center",
                                                        verticalAlign: "middle",
                                                      }
                                                }
                                              >
                                                {!isPaidPlan ? (
                                                  <>
                                                    <TableHeader headers={SQScoreHeader} />
                                                    78.9
                                                  </>
                                                ) : (
                                                  team["Consensus Opener"]
                                                )}
                                              </td>
                                              <td
                                                style={
                                                  !isPaidPlan
                                                    ? {
                                                        textAlign: "center",
                                                        color: "transparent",
                                                        textShadow: "0 0 7px #000",
                                                        position: "relative",
                                                      }
                                                    : {
                                                        textAlign: "center",
                                                        verticalAlign: "middle",
                                                      }
                                                }
                                              >
                                                {!isPaidPlan ? (
                                                  <>
                                                    <TableHeader headers={SQScoreHeader} />
                                                    78.9
                                                  </>
                                                ) : (
                                                  Math.abs(team["Consensus"])
                                                )}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                  verticalAlign: "middle",
                                                }}
                                              >
                                                {team["Play"] ? "Play" : "-"}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </tbody>
                                </table>
                                {isDataLoaded && pendingPlays.length === 0 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop: "50px",
                                    }}
                                  >
                                    <h3 style={{ margin: "0 auto" }}>
                                      There are no pending{" "}
                                      {leagueFromUrl.toLowerCase() === "nba"
                                        ? "NBA"
                                        : leagueFromUrl.toLowerCase() === "wnba"
                                        ? "WNBA"
                                        : "NCAA"}{" "}
                                      Best Bets games available.{" "}
                                    </h3>
                                  </div>
                                )}
                              </div>
                            </ScrollableTable>
                          </>
                        )}
                        <div className="text-center">
                          {showPendingPlayLoadMoreButton && (
                            <button
                              className="btn btn-primary"
                              style={{ marginTop: "-.5rem" }}
                              onClick={handlePendingPlayLoadMore}
                            >
                              Load More..
                            </button>
                          )}
                        </div>
                      </>
                    )}
                    {!tableLoaded && (
                      <TableSkeleton
                        tableHeader={miscTableHeader}
                        tableRowsCount={30}
                        className="table-space"
                        tableHeaderHeight="90px"
                      />
                    )}
                  </div>
                </section>
              )}
              {/* End Title side */}
            </div>
            <div
              className="p-4"
              style={{
                backgroundColor: "#f3f4f6",
              }}
            >
              <ContentfulBlogs user={user} />
            </div>

            <WinningMetrics targetPage={"BestBets"} />
          </main>
        </div>
      </MainLayout>
    </>
  );
};

export default BestBets;

export const getServerSideProps = appServerSideProps(async ({ appService }) => {
  const isUserLogged = appService.getUser();

  let user = null;

  if (isUserLogged) {
    user = await appService.get("api/account");
  }
  if (Utils.isTrader(user)) {
    return {
      redirect: {
        destination: "/trader-dashboard",
        permanent: false,
      },
    };
  }
  return { props: { user } };
});
